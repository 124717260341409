import "firebase/auth"
import "firebase/firestore"

import firebase from "firebase/app"

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

try {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
    firebase.auth().useDeviceLanguage()
  }
} catch (error) {
  console.error("Firebase initialization error", error.stack)
}

export const firebaseAuth = firebase.auth()
export const firebaseFirestore = firebase.firestore()
